import { Controller } from '@hotwired/stimulus'
import { request } from './helpers'

export default class extends Controller {
  static targets = ['drawButton', 'loading', 'result']
  static values = {
    campaignId: Number
  }

  drawWinner(event) {
    const timeToDraw = 1500;

    this.loadingTarget.classList.add('d-block');
    this.loadingTarget.classList.remove('d-none');

    setTimeout(async () => {
      const response = await request(`/admin/campaigns/${this.campaignIdValue}/raffles`, { method: 'POST' });

      if (response.status == 200) {
        const html = await response.text();
        this.resultTarget.insertAdjacentHTML('afterbegin', html);
      }

      if (response.status == 204) {
        this.drawButtonTarget.classList.add('d-none');
      }

      this.loadingTarget.classList.add('d-none');
      this.loadingTarget.classList.remove('d-block');
    }, timeToDraw);
  }
}
