import { Controller } from '@hotwired/stimulus'
import Cleave from 'cleave.js'
import 'cleave.js/dist/addons/cleave-phone.br'

export default class extends Controller {
  connect() {
    new Cleave(this.element, {
      phone: true,
      phoneRegionCode: 'br'
    })
  }
}
