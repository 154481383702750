import { Controller } from '@hotwired/stimulus'
import Cleave from 'cleave.js'

export default class extends Controller {
  connect() {
    new Cleave(this.element, {
      delimiters: ['-'],
      blocks: [3, 4],
      uppercase: true
    })
  }
}
