export function findElement(root, selector) {
  if (typeof root === 'string') {
    selector = root;
    root = document;
  }
  return root.querySelector(selector);
}

export function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`);
  if (element) {
    return element.getAttribute('content');
  }

  return null;
}

export function removeElement(el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
}

export function insertAfter(el, referenceNode) {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}

export const numberWithPrecision = number => new Intl.NumberFormat('id', { maximumSignificantDigits: 4 }).format(number)

export const request = async (url, customOptions = {}) => {
  const standardOptions = {
    headers: { 'X-CSRF-Token': getMetaValue('csrf-token') }
  }

  const options = Object.assign(standardOptions, customOptions)

  return await fetch(url, options)
};

export const requestApi = async (url, customOptions = {}) => {
  const response = requestUrl(url, customOptions)

  const status = response.status
  const json = await response.json()

  return { status: status, json: json }
}
